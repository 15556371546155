<template>
    <v-container style="margin-top: 75px;">
        <v-card>
            <v-data-table :headers="headers" :items="banners" :server-items-length="totalItems" :options.sync="options"
                :loading="loading" :footer-props="footerProps" class="elevation-1">

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>文章列表</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="addBanner()">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>新增文章</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>

                <template v-slot:item.mainText="{ item }">
                    <v-avatar>
                        <v-icon @click="showMainText(item)">mdi-eye</v-icon>
                    </v-avatar>
                </template>

                <template v-slot:item.mainImgUrl="{ item }">
                    <v-avatar>
                        <v-icon @click="showImage(item)">mdi-eye</v-icon>
                    </v-avatar>
                </template>

                <template v-slot:item.action="{ item }">
                    <!-- 编辑按钮 -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="editItemMethod(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>编辑</span> <!-- 编辑按钮的提示信息 -->
                    </v-tooltip>

                    <!-- 禁用按钮 -->
                    <v-tooltip bottom>

                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="disableItem(item)">
                                <v-icon>mdi-close-octagon</v-icon>
                            </v-btn>
                        </template>
                        <span>删除</span> <!-- 禁用按钮的提示信息 -->
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="addDialog" max-width="500px">
            <v-card>
                <!-- 添加框标题 -->
                <v-card-title class="headline">添加新文章</v-card-title>
                <!-- 添加框表单内容 -->
                <v-card-text>
                    <!-- 标题输入框 -->
                    <v-text-field label="文章标题-长度18" v-model="title" maxlength="18"></v-text-field>
                    <!-- 简介输入框 -->
                    <v-textarea label="文章概述-长度56" v-model="description" maxlength="56"></v-textarea>
                    <v-textarea v-model="mainText" label="文章正文" rows="10" auto-grow></v-textarea>
                    <!-- 图片上传组件 -->
                    <v-file-input label="图片上传" v-model="imageFile" accept="image/*" @change="onFileSelected('add')"
                        required></v-file-input>
                </v-card-text>
                <v-card-text v-if="false">
                    <div>预览</div>
                    <div class="formatted-text">
                        <h3>{{ title }}</h3>
                        <p>{{ description }}</p>
                        <pre>{{ mainText }}</pre>
                        <v-img :src="imageUrl" max-width="100%"></v-img>
                    </div>
                </v-card-text>
                <!-- 添加框底部操作按钮 -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 取消按钮 -->
                    <v-btn color="blue darken-1" text @click="addDialog = false">取消</v-btn>
                    <!-- 提交按钮，点击时调用添加项目的逻辑 -->
                    <v-btn color="primary" dark @click="addProject">添加</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
                <!-- 添加框标题 -->
                <v-card-title class="headline">修改文章信息</v-card-title>
                <!-- 添加框表单内容 -->
                <v-card-text>
                    <!-- 标题输入框 -->
                    <v-text-field label="文章标题-长度18" v-model="editItem.title" maxlength="18"></v-text-field>
                    <!-- 简介输入框 -->
                    <v-textarea label="文章概述-长度56" v-model="editItem.smartTitle" maxlength="56"></v-textarea>
                    <v-textarea v-model="editItem.mainText" label="文章正文" rows="10" auto-grow></v-textarea>
                    <!-- 图片上传组件 -->
                    <v-file-input label="图片上传" v-model="imageFile" accept="image/*" @change="onFileSelected('edit')"
                        required></v-file-input>
                </v-card-text>
                <v-card-text v-if="false">
                    <div>预览</div>
                    <div class="formatted-text">
                        <h3>editItem.title</h3>
                        <p>editItem.smartTitle</p>
                        <pre>{{ editItem.mainText }}</pre>
                    </div>
                </v-card-text>
                <!-- 添加框底部操作按钮 -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 取消按钮 -->
                    <v-btn color="blue darken-1" text @click="editDialog = false">取消</v-btn>
                    <!-- 提交按钮，点击时调用添加项目的逻辑 -->
                    <v-btn color="primary" dark @click="editProject()">修改</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="uploadDialog" persistent width="300">
            <v-card>
                <v-card-title class="headline">请求中...</v-card-title>
                <v-card-text>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showImageDialog" max-width="500px">
            <v-card>
                <v-img :src="showImageUrl" @click="showImageDialog = false">

                </v-img>
                <v-card-title>图片展示</v-card-title>
                <v-card-subtitle>
                    当前文章配图
                    <v-btn icon class="float-right" @click="downloadImage">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-card-subtitle>
            </v-card>

        </v-dialog>

        <v-dialog v-model="showTextDialog" max-width="500px">
            <v-card>
                <v-card-text>
                    <div>文章正文</div>
                    <div class="formatted-text">
                        <pre>{{ showText }}</pre>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import api from "../../api/api";
import axios from 'axios';
const pre = 'http://120.26.117.241:8055/msn/';
const _ = require('lodash');
export default {
    data() {
        return {
            showImageUrl: '',
            showImageDialog: false,
            showTextDialog: false,
            showText: '',
            uploadDialog: false,
            editDialog: false,
            editItem: {
                title: '',
                smartTitle: '',
                imageFile: null,
                order: '',

            },
            statusItems: [
                { text: '启用', value: 1 },
                { text: '禁用', value: 0 },
            ],
            title: '',
            description: '',
            articleText: '',
            mainText: '',
            imageFile: null,
            imageUrl: null,
            editImageUrl: null,
            order: 0, // 初始值为0，非负数
            addDialog: false,
            headers: [
                { text: '文章标题', align: 'title', sortable: false, value: 'title', class: 'truncate' },
                { text: '文章概述', value: 'smartTitle', sortable: false, class: 'truncate' },
                { text: '文章正文', value: 'mainText', sortable: false, class: 'truncate' },
                { text: '文章配图', value: 'mainImgUrl', sortable: false, width: '100px' },
                { text: '创建时间', value: 'createTime', sortable: false, width: '200px' },
                { text: '更新时间', value: 'lastUpdateTime', sortable: false, width: '200px' },
                { text: '操作', value: 'action', sortable: false, width: '100px' },
            ],
            banners: [],
            options: {
                page: 1,
                itemsPerPage: 5,
            },
            loading: false,
            totalItems: 0,
            footerProps: {
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: true,
                showCurrentPage: true,
                showTotalItems: true
            }
        };
    },
    watch: {
        options: {
            handler() {
                this.fetchItems();
            },
            deep: true,
        },
        showImageDialog: {
            handler() {
                if (this.showImageDialog == false) {
                    this.showImageUrl = '';
                }
            }
        },
        showTextDialog: {
            handler() {
                if (this.showTextDialog == false) {
                    this.showTextDialog = '';
                }
            }
        },
    },
    created() {
        this.fetchItems();
    },
    methods: {
        async downloadImage() {
            try {
                const response = await axios({
                    url: this.showImageUrl,
                    method: 'GET',
                    responseType: 'blob' // 重要：设置响应类型为 blob
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'downloaded-image.jpg'); // 下载文件名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading image', error);
            }
        },
        showMainText(item) {
            this.showText = item.mainText;
            this.showTextDialog = true;
        },
        showImage(item) {
            this.showImageUrl = item.mainImgUrl;
            this.showImageDialog = true;
            // return item.imageFile;
        },
        onFileSelected(type) {
            this.uploadDialog = true;
            api.postFile('/center/upload', this.imageFile).then(response => {
                // 上传成功的处理
                if (response.status == 200) {
                    console.log(response.data.data);
                    if (type == 'add') {
                        this.imageUrl = pre + response.data.data;
                    } else if (type == 'edit') {
                        this.editImageUrl = pre + response.data.data;
                    }
                }
                // this.imageFile = null;
                this.uploadDialog = false;
            }).catch(error => {
                // 上传失败的处理
                console.error('上传失败', error);
                // this.imageFile = null;
                this.uploadDialog = false;
            });
        },
        addBanner() {
            this.addDialog = true;
        },
        addProject() {
            api.post('/center/newArt', {
                'title': this.title,
                'smartTitle': this.description,
                'mainText': this.mainText,
                'mainImgUrl': this.imageUrl
            }
            ).then(response => {
                if (response.status == 200) {
                    confirm('添加成功');
                }
                this.addDialog = false;
                this.imageUrl = null;
                this.fetchItems();
            }).catch(error => {
                console.log(error);
                this.imageUrl = null;
            })
        },
        fetchItems() {
            this.loading = true;
            api.get(`/center/queryArtsByPage?pageNum=${this.options.page}&pageSize=${this.options.itemsPerPage}`).then(response => {
                this.loading = false;
                if (response.status === 200) {
                    this.totalItems = response.data.data.total;
                    this.banners = response.data.data.records;
                    for (const banner of this.banners) {
                        banner.createTime = this.formatDate(banner.createTime);
                        banner.lastUpdateTime = this.formatDate(banner.lastUpdateTime);
                    }
                    console.log(this.banner)
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 返回的月份是从 0 开始的
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        editItemMethod(item) {
            this.editDialog = true;
            this.editItem = _.cloneDeep(item);
            console.log(item);
            console.log(this.editItem)
        },
        editProject() {
            let url = this.editItem.imageUrl;
            if (this.editImageUrl != null && this.editImageUrl != '') {
                url = this.editImageUrl;
            }
            this.editItem.mainImgUrl = url;
            api.post('/center/updateArt', this.editItem).then(response => {
                if (response.status == 200) {
                    confirm('修改成功');
                }
                this.editDialog = false;
                this.editImageUrl = null;
                this.fetchItems();
            }).catch(error => {
                console.log(error);
                this.editImageUrl = null;
            });
        },
        disableItem(item) {
            api.post('/center/delArt', {
                'id': item.id
            }).then(response => {
                if (response.status == 200) {
                    confirm('删除成功');
                    this.fetchItems();
                }
            });
            // this.fetchItems();
        }
    },
};
</script>

<style scoped>
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status {
    width: 50px;
}

.formatted-text {
    white-space: pre-wrap;
    /* 保留空格和换行符 */
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
}
</style>